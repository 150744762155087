section.claims-part {
    /* background-image: url(https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1675167991/SYC-SYG-Logos-Images/elite-bg_rgyes2.jpg); */
    background-repeat: no-repeat;
    float: none;
}
.form-box {
    background-color: #fff;
    box-shadow: 0 0 14px 0 #929292;
    margin-bottom: 30px;
    padding: 30px;
}

h4.prf-hed {
    color: #00a9e0;
    margin-bottom: 20px;
}
.form-floating {
    margin-bottom: 10px;
    margin-top: 5px;
}
div#profileBox{
    max-width: 960px !important;
    margin: 0 auto !important;
    /* border-radius: 8px; */
    /* margin-top: -240px !important; */
    margin-top: -206px !important;
    z-index: 1111 !important;
    position: relative !important;
    /* padding: 1px 42px !important; */
}
ul.timeline {
    list-style-type: none;
    padding-left: 12px;
    position: relative;
}

ul.timeline.contact-info-profile>li:last-child .form-box {
    margin-bottom: 0;
}

ul.timeline.contact-info-profile:before {
    height: 98%;
}
.single-li {
    margin-bottom: 0;
}
ul.timeline:before {
    background: #d4d9df;
    content: " ";
    display: inline-block;
    height: 99%;
    left: -14.4px;
    position: absolute;
    width: 2px;
    z-index: 400;
    top: 13px;
}
/* .single-li.timeline>li {
    padding-bottom: 30px;
} */
ul.timeline>li:before {
    background: #e38c67;
    border: 3px solid #fff;
    border-radius: 50%;
    content: " ";
    display: inline-block;
    height: 20px;
    left: -23px;
    position: absolute;
    width: 20px;
    z-index: 400;
}