.text-right {
    text-align: right;
}
div#W9box{
    max-width: 960px !important;
    background-color: #fff;
    box-shadow: 0 0 14px 0 #929292;
    margin: 0 auto !important;
    /* border-radius: 8px; */
    margin-top: -232px !important;
    z-index: 1111 !important;
    position: relative !important;
    padding: 30px !important;
}