ul.timeline>li {
    margin: 0;
}






.prfil-set {
    /* border: 1px solid #ccc; */
    margin-left: 0px;
    margin-right: 0;
    padding: 7px 7px;

    margin-bottom: 0;
    overflow: auto;
}

.form-box .round-red-btn {
    border: 0;
    padding: 8px 50px;
}

.round-red-btn {
    align-items: center;
    background: #00a9e0;
    border: 1px solid #00a9e0;
    border: 0;
    border-radius: 30px !important;
    color: #fff;
    display: flex;
    font-family: Sarabun, sans-serif;
    font-size: 18px;
    font-weight: 400;
    justify-content: center;
    padding: 10px 28px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.round-red-btn:hover {
    background: #e38c67;
    border: 1px solid #e38c67;
    color: #fff;
    text-decoration: none;
    transition: all .4s;
}