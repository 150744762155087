@media (min-width: 1200px) and (max-width: 2400px){
.video-outer video {
    height: 100%;
    margin-bottom: 60px;
}
}
div#faqbox{
    max-width: 960px !important;
    background-color: #fff;
    box-shadow: 0 0 14px 0 #929292;
    margin: 0 auto !important;
    /* border-radius: 8px; */
    /* margin-top: -232px !important; */
    margin-top: -161px !important;
    z-index: 1111 !important;
    position: relative !important;
    padding: 15px 15px !important;
}
/* video.faq-vid {
    padding-bottom: 35px;
} */
section#faqtest{
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}
.slides-here {
    position: relative;
    margin-top: -45px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    section#faqtest {
        padding-bottom: 10px;
    }
}